import { PrinterIcon, XIcon } from "@heroicons/react/outline";
import React, { useEffect, useState, Fragment, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { PencilIcon } from "@heroicons/react/solid";
import { CartList } from "./Cart";
import ReactToPrint from "react-to-print";

export const OrderView = () => {
  let params = useParams();
  let navigate = useNavigate();
  const orderId = params.orderId;

  const [error, setError] = useState("");
  const [order, setOrder] = useState("");
  const [edit, setEdit] = useState(false);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_SERVER + "/api/v2/orders/" + orderId,
        config,
        {}
      )
      .then((response) => {
        setOrder(response.data);
      })
      .catch((err) => {
        setError(err.response.data.error);
      });
  }, []);

  useEffect(() => {
    if (order && !order.products[0].image) {
      const products = order.products.map(({ _id }) => _id);
      axios
        .get(process.env.REACT_APP_SERVER + "/api/v2/products/protected", {
          ...config,
          params: {
            ids: products,
          },
        })
        .then((response) => {
          const products = response.data.data.map((value) => ({
            ...value,
            qty: order.products.find(({ _id }) => {
              return _id === value._id;
            }).qty,
          }));
          setOrder({ ...order, products: products });
        })
        .catch((err) => {
          console.log(err);
          setError(err);
        });
    }
  }, [order]);

  const componentRef = useRef();

  return (
    order && (
      <>
        <ReactToPrint
          trigger={() => (
            <button className="my-2">
              <PrinterIcon className="h-7 ml-7 lg:ml-0" />
            </button>
          )}
          content={() => componentRef.current}
        />
        <button
          type="button"
          onClick={() => {
            navigate("/orders", { replace: true });
          }}
          className="absolute right-5 top-4"
        >
          <XIcon className="w-8 h-8"></XIcon>
        </button>
        <div ref={componentRef}>
          <h2 className="ml-6 lg:ml-0  text-2xl font-semibold w-10/12 mb-2">
            Заказ на{" "}
            {new Date(order.deliveryInfo.date).toLocaleDateString("ru-RU") +
              " " +
              (!order.deliveryInfo.time.includes("GMT")
                ? order.deliveryInfo.time
                : new Date(order.deliveryInfo.time).getHours() +
                  ":" +
                  new Date(order.deliveryInfo.time).getMinutes())}
          </h2>
          <table className="w-full text-left border-2 border-gray-500 text-lg">
            <tr className="odd:bg-white even:bg-gray-200">
              <th className="p-2 max-w-[10rem]">ФИО</th>
              <td>{order.fullName}</td>
            </tr>
            <tr className="odd:bg-white even:bg-gray-200">
              <th className="p-2 max-w-[10rem]">Тел</th>
              <td>
                <a href={"tel:" + order.phoneNumber}>{order.phoneNumber}</a>
              </td>
            </tr>
            <tr className="odd:bg-white even:bg-gray-200">
              <th className="p-2 max-w-[10rem]">Почта</th>
              <td>
                <a href={"mailto:" + order.email}>{order.email}</a>
              </td>
            </tr>
            <tr className="odd:bg-white even:bg-gray-200">
              <th className="p-2 max-w-[10rem]">Способ получения</th>
              <td>{order.isShipping ? "Доставка" : "Самовывоз"}</td>
            </tr>
            <tr className="odd:bg-white even:bg-gray-200">
              <th className="p-2 max-w-[10rem]">Адрес</th>
              <td>
                <a
                  href={`https://yandex.ru/maps/?text=${order.deliveryInfo.address}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  {order.deliveryInfo.address}
                  {order.deliveryInfo.flat ? (
                    <>
                      <b>, Кв: </b>
                      {order.deliveryInfo.flat}
                    </>
                  ) : (
                    ""
                  )}
                  {order.deliveryInfo.entrance ? (
                    <>
                      <b>, Подъезд: </b>
                      {order.deliveryInfo.entrance}
                    </>
                  ) : (
                    ""
                  )}
                  {order.deliveryInfo.floor ? (
                    <>
                      <b>, Этаж: </b>
                      {order.deliveryInfo.floor}
                    </>
                  ) : (
                    ""
                  )}
                  {order.deliveryInfo.intercom ? (
                    <>
                      <b>, Домофон: </b>
                      {order.deliveryInfo.intercom}
                    </>
                  ) : (
                    ""
                  )}
                </a>
              </td>
            </tr>
            <tr className="odd:bg-white even:bg-gray-200">
              <th className="p-2 max-w-[10rem]">Время</th>
              <td>
                {new Date(order.deliveryInfo.date).toLocaleDateString("ru-RU") +
                  " " +
                  (!order.deliveryInfo.time.includes("GMT")
                    ? order.deliveryInfo.time
                    : new Date(order.deliveryInfo.time).getHours() +
                      ":" +
                      new Date(order.deliveryInfo.time).getMinutes())}
              </td>
            </tr>

            {order.isShipping && order.deliveryInfo.isShipToAnother ? (
              <tr classNamdescriptione="odd:bg-white even:bg-gray-200">
                <th className="p-2 max-w-[10rem]">Доставка другому человеку</th>
                <td>
                  <tr>
                    <th>Имя</th>
                    <td>{order.deliveryInfo.recipInfo.name}</td>
                  </tr>
                  <tr>
                    <th>Контактный телефон</th>
                    <td>{order.deliveryInfo.recipInfo.phone}</td>
                  </tr>
                  <tr>
                    <th>Текст открытки</th>
                    <td>{order.deliveryInfo.recipInfo.note}</td>
                  </tr>
                </td>
              </tr>
            ) : null}
            {order.comment ? (
              <tr className="odd:bg-white even:bg-gray-200">
                <th className="p-2 max-w-[10rem]">Комментарий</th>
                <td>{order.comment}</td>
              </tr>
            ) : null}
            {order.deliveryInfo.comment ? (
              <tr className="odd:bg-white even:bg-gray-200">
                <th className="p-2 max-w-[10rem]">Комментарий</th>
                <td>{order.deliveryInfo.comment}</td>
              </tr>
            ) : null}
            <tr className="odd:bg-white even:bg-gray-200">
              <th className="p-2 max-w-[10rem]">Способ оплаты</th>
              <td>{order.payment ? "На сайте" : "При получении"}</td>
            </tr>
            <tr className="odd:bg-white even:bg-gray-200">
              <th className="p-2 max-w-[10rem]">Статус</th>
              <td>{order.status}</td>
            </tr>
          </table>
          <div className="mt-4">
            <span className="font-bold text-lg">Корзина</span>
            {order.status === "обработка" && !edit && (
              <PencilIcon
                className="h-6 text-accent hover:cursor-pointer hover:opacity-70 inline-block mx-2"
                onClick={(e) => {
                  e.preventDefault();
                  setEdit(!edit);
                }}
              />
            )}
            <ol className="text-center mt-5">
              {order && (
                <CartList
                  order={order}
                  setOrder={setOrder}
                  edit={edit}
                  setEdit={setEdit}
                />
              )}
            </ol>
          </div>
        </div>
        {order.status && !edit && (
          <ChangeStatus order={order} setOrder={setOrder}></ChangeStatus>
        )}
      </>
    )
  );
};

function ChangeStatus({ order, setOrder }) {
  const confirmOrder = (status) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };

    axios
      .put(
        `${process.env.REACT_APP_SERVER}/api/v2/orders/${order._id}/confirm`,
        {},
        config
      )
      .then((response) => {
        setOrder({ ...order, status: "подтвержден" });
      })
      .catch((err) => {});
  };

  const cancelOrder = (status) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };

    axios
      .put(
        `${process.env.REACT_APP_SERVER}/api/v2/orders/${order._id}/cancel`,
        {},
        config
      )
      .then((response) => {
        setOrder({ ...order, status: "отменен" });
      })
      .catch((err) => {});
  };

  const completeOrder = (status) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };

    axios
      .put(
        `${process.env.REACT_APP_SERVER}/api/v2/orders/${order._id}/complete`,
        {},
        config
      )
      .then((response) => {
        setOrder({ ...order, status: "завершен" });
      })
      .catch((err) => {});
  };

  return (
    <div className="flex gap-3 my-4 ml-auto">
      {order.status.toLowerCase() === "обработка" ? (
        <>
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              cancelOrder();
            }}
            className="inline-flex justify-center rounded-md border border-transparent bg-red-500/95 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          >
            Отменить
          </button>
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              confirmOrder();
            }}
            className="inline-flex justify-center rounded-md border border-transparent bg-accent/95 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          >
            Подтвердить
          </button>
        </>
      ) : order.status.toLowerCase() === "подтвержден" ? (
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            completeOrder();
          }}
          className="inline-flex justify-center rounded-md border border-transparent bg-accent/95 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
        >
          Завершить
        </button>
      ) : null}
    </div>
  );
}
