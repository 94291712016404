import React from "react";

export const AdminContext = React.createContext(null);

export default function AdminProvider({ children }) {
  const [isChanging, setChanging] = React.useState(false);
  const [productState, setPS] = React.useState({
    page: 0,
    pageCount: 25,
    filter: null,
    order: null,
  });

  const store = {
    changing: [isChanging, setChanging],
    productState: { value: productState, setValue: setPS },
  };

  return (
    <AdminContext.Provider value={store}>{children}</AdminContext.Provider>
  );
}
