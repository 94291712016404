import React, { useEffect, useState } from "react";
import { DragDropContext } from "react-dnd";
import { RefreshIcon } from "@heroicons/react/outline";
import HTML5Backend from "react-dnd-html5-backend";
import { Convert } from "mongo-image-converter";
import { Board } from "./Components/Board";
import _ from "lodash";
import "./styles.css";
import axios from "axios";
import { AdminContext } from "../../Components/shared/AdminProvider";
import { PencilIcon, XIcon } from "@heroicons/react/solid";

var columnIds = 0;
var cardIds = 0;

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function CategoryDnD() {
  const [columns, setColumns] = useState([]);
  const [cards, setCards] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [reorder, setReorder] = useState(false);
  const [isChanging, setChanging] = React.useContext(AdminContext).changing;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };

  const changeDesc = (...e) => {
    const [columnId, event] = e;
    var column = columns.find(({ _id }) => _id === columnId);
    column.description = event.target.value;
    column.update = true;
    setColumns((columns) => [
      ...columns.filter(({ _id }) => _id !== columnId),
      column,
    ]);
    setChanging(true);
  };

  const setImage = async (image, columnId) => {
    const convertedImage = await Convert(image);
    var column = columns.find(({ _id }) => _id === columnId);
    column.image = convertedImage;
    column.update = true;
    setColumns((columns) => [
      ...columns.filter(({ _id }) => _id !== columnId),
      column,
    ]);
    setChanging(true);
  };

  const deleteImage = (columnId) => {
    let column = columns.find(({ _id }) => _id === columnId);
    column.image = "";
    column.update = true;
    setColumns((columns) => [
      ...columns.filter(({ _id }) => _id !== columnId),
      column,
    ]);
    setChanging(true);
  };

  const setCategories = (categories) => {
    var columns = [];
    var cards = [];
    categories.forEach(({ _id, title, index, subCats, image, description }) => {
      const newSubCats = subCats.map((value) => ({
        title: value,
        _id: cardIds++,
      }));
      cards.push(newSubCats);
      columns.push({
        _id,
        title,
        index,
        deletable: false,
        _new: false,
        update: false,
        subCats: newSubCats.map(({ _id }) => {
          return _id;
        }),
        image,
        description,
      });
    });

    setColumns(columns);
    setCards(cards.flat(1));
  };

  const fetchPrivateData = async () => {
    setFetching(true);

    axios
      .get(process.env.REACT_APP_SERVER + "/api/v2/categories")
      .then((response) => {
        setCategories(response.data.data);
      })
      .catch((err) => {})
      .then(() => {
        setFetching(false);
      });
  };
  useEffect(() => {
    fetchPrivateData();
  }, []);

  const addColumn = (_title) => {
    const title = capitalizeFirstLetter(_title.toLowerCase());
    if (!title) return;
    if (columns.find((column) => column.title === title)) return;
    const newColumn = {
      _id: columnIds++,
      title,
      deletable: false,
      index: 1,
      _new: true,
      update: false,
      subCats: [],
      description: "",
    };
    setColumns((columns) => [...columns, newColumn]);
    setChanging(true);
  };

  const deleteColumn = (columnId) => {
    var column = columns.find(({ _id }) => _id === columnId);
    column.deletable = !column.deletable;
    setColumns((columns) => [
      ...columns.filter(({ _id }) => _id !== columnId),
      column,
    ]);
    setChanging(true);
  };

  const addCard = (columnId, _title) => {
    const title = capitalizeFirstLetter(_title.toLowerCase());
    if (!title) return;
    if (cards.find((card) => card.title === title)) return;
    const newCard = {
      _id: cardIds++,
      title,
      deletable: false,
      _new: true,
    };
    setCards((cards) => [...cards, newCard]);
    setColumns((columns) =>
      columns.map((column) =>
        column._id === columnId
          ? {
              ...column,
              subCats: [...column.subCats, newCard._id],
              update: true,
            }
          : column
      )
    );
    setChanging(true);
  };

  const moveCard = (cardId, destColumnId, cardIndex) => {
    setColumns((columns) => [
      ...columns.map((column) => ({
        ...column,
        subCats: _.flowRight(
          // 2) If this is the destination column, insert the cardId.
          (ids) =>
            column._id === destColumnId
              ? [...ids.slice(0, cardIndex), cardId, ...ids.slice(cardIndex)]
              : ids,
          // 1) Remove the cardId for all columns
          (ids) => ids.filter((id) => id !== cardId)
        )(column.subCats),
        update: true,
      })),
    ]);
    setChanging(true);
  };

  const deleteCard = (cardId, columnId) => {
    var card = cards.find(({ _id }) => _id === cardId);
    card.deletable = !card.deletable;
    setCards([...cards.filter(({ _id }) => _id !== cardId), card]);
    var column = columns.find(({ _id }) => _id === columnId);
    column.update = true;
    setColumns([...columns.filter(({ _id }) => _id !== columnId), column]);
  };

  const saveCategories = async (e) => {
    const normalizeIndexes = (categories) => {
      return categories
        .sort((a, b) => (a.index > b.index ? 1 : -1))
        .map((category, index) => {
          if (category.index === index) return category;
          category.index = index;
          category.update = true;
          return category;
        });
    };

    const createCategories = async () => {
      const request = {
        categories: columns
          .filter(({ _new }) => _new)
          .map((object) => {
            delete object["_id"];
            return object;
          }),
      };
      request.categories.length &&
        (await axios
          .post(
            process.env.REACT_APP_SERVER + "/api/v2/categories",
            request,
            config
          )
          .then((response) => {})
          .catch((err) => {}));
    };

    const updateCategories = async () => {
      const request = {
        categories: columns.filter(({ update, _new }) => update && !_new),
      };

      request.categories.length &&
        (await axios
          .put(
            process.env.REACT_APP_SERVER + "/api/v2/categories",
            request,
            config
          )
          .then((response) => {})
          .catch((err) => {}));
    };

    const deleteCategories = async () => {
      const request = {
        deletable: [
          ...columns
            .filter(({ deletable }) => deletable)
            .map(({ _id }) => {
              return _id;
            }),
        ],
      };
      request.deletable.length &&
        (await axios
          .delete(process.env.REACT_APP_SERVER + "/api/v2/categories", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
            data: request,
          })
          .then((response) => {})
          .catch((err) => {
            console.log(err);
          }));
    };

    e.preventDefault();
    try {
      normalizeIndexes(columns);
      setColumns(
        columns
          .filter(({ deletable, _new }) => !deletable || !_new)
          .map((column) => {
            column.subCats = cards
              .filter(
                ({ deletable, _id }) =>
                  !deletable && column.subCats.includes(_id)
              )
              .map(({ title }) => title);
            return column;
          })
      );
      setFetching(true);
      await deleteCategories();
      await updateCategories();
      await createCategories();
      fetchPrivateData();
      setChanging(false);
    } catch (error) {
      console.warn(error.message);
    }
  };

  return fetching ? (
    <span className="animate-spin absolute">
      <RefreshIcon className="w-7 h-7 text-accent "></RefreshIcon>
    </span>
  ) : (
    <>
      <button
        className="flex items-center gap-1 mb-2 px-3 py-2 bg-gray-300 rounded-md hover:opacity-70 dark:bg-gray-600"
        onClick={(e) => {
          e.preventDefault();
          setReorder(true);
        }}
      >
        <PencilIcon className="h-5" />
        Изменить порядок
      </button>
      <Board
        cards={cards}
        columns={columns}
        moveCard={moveCard}
        addCard={addCard}
        addColumn={addColumn}
        deleteColumn={deleteColumn}
        deleteCard={deleteCard}
        saveCategories={saveCategories}
        setImage={setImage}
        deleteImage={deleteImage}
        changeDesc={changeDesc}
      />
      {reorder && (
        <div className="fixed w-screen h-screen top-0 left-0 bg-gray-600/30">
          <XIcon
            onClick={(e) => {
              e.preventDefault();
              setReorder(false);
            }}
            className="absolute top-10 right-10 h-7 hover:cursor-pointer"
          />
          <div className="inset-0 h-screen flex place-items-center place-content-center">
            <div className="bg-neutral dark:bg-gray-800 p-5 rounded-lg max-h-56">
              В разработке...
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DragDropContext(HTML5Backend)(CategoryDnD);
