import React from "react";
import { Column } from "./Column";
import { DraggableCard } from "./Card";
import { TextForm } from "./TextForm";

export function Board({
  cards,
  columns,
  moveCard,
  addCard,
  addColumn,
  deleteColumn,
  deleteCard,
  saveCategories,
  setImage,
  deleteImage,
  changeDesc,
}) {
  return (
    <div className="flex flex-row gap-6 flex-wrap items-start">
      {columns
        .sort((a, b) => (a.index > b.index ? 1 : -1))
        .map((column) => (
          <Column
            key={column._id}
            id={column._id}
            title={column.title}
            addCard={addCard.bind(null, column._id)}
            deleteColumn={deleteColumn.bind(null, column._id)}
            deletable={column.deletable}
            setImage={setImage}
            deleteImage={deleteImage}
            image={column.image}
            desc={column.description}
            changeDesc={changeDesc.bind(null, column._id)}
          >
            {column.subCats
              .map((cardId) => cards.find((card) => card._id === cardId))
              .map((card, index) => (
                <DraggableCard
                  key={card._id}
                  _id={card._id}
                  columnId={column._id}
                  columnIndex={index}
                  title={card.title}
                  moveCard={moveCard}
                  deleteCard={deleteCard.bind(null, card._id, column._id)}
                  deletable={card.deletable}
                />
              ))}
            {column.subCats.length === 0 && (
              <DraggableCard
                isSpacer
                moveCard={(cardId) => moveCard(cardId, column._id, 0)}
              />
            )}
          </Column>
        ))}
      <div className="Column">
        <TextForm onSubmit={addColumn} placeholder="Добавить категорию..." />
      </div>
      <button
        onClick={saveCategories}
        className="bg-accent h-fit px-4 py-2 rounded-md text-white"
      >
        Сохранить
      </button>
    </div>
  );
}
