// import React from "react";
// import { DragSource, DropTarget } from "react-dnd";
// import cn from "classnames";
// import _ from "lodash";
// import { TrashIcon } from "@heroicons/react/solid";
// import { SaveIcon } from "@heroicons/react/outline";

// export function Card(props) {
//   return _.flowRight(
//     props.connectDragSource,
//     props.connectDropTarget
//   )(
//     <div
//       className={cn(
//         "flex justify-between py-4 px-2 bg-gray-100 dark:bg-gray-600 rounded-md cursor-pointer border-2 border-gray-100 dark:border-gray-600",
//         {
//           "opacity-70": props.isDragging,
//           "opacity-0": props.isSpacer,
//           "line-through opacity-50": props.deletable,
//         }
//       )}
//     >
//       {!props.isSpacer ? (
//         <>
//           <div className="capitalize">{props.title} </div>
//           <TrashIcon
//             onClick={props.deleteCard}
//             className="w-7 cursor-pointer text-pink-500 opacity-85"
//           ></TrashIcon>
//         </>
//       ) : (
//         <SaveIcon />
//       )}
//     </div>
//   );
// }

// export const DraggableCard = _.flowRight([
//   DropTarget(
//     "Card",
//     {
//       hover(props, monitor) {
//         const { columnId, columnIndex } = props;
//         const draggingItem = monitor.getItem();
//         if (draggingItem._id !== props.id) {
//           props.moveCard(draggingItem._id, columnId, columnIndex);
//         }
//       },
//     },
//     (connect) => ({
//       connectDropTarget: connect.dropTarget(),
//     })
//   ),
//   DragSource(
//     "Card",
//     {
//       beginDrag(props) {
//         return { id: props.id };
//       },

//       isDragging(props, monitor) {
//         return props.id === monitor.getItem()._id;
//       },
//     },
//     (connect, monitor) => ({
//       connectDragSource: connect.dragSource(),
//       isDragging: monitor.isDragging(),
//     })
//   ),
// ])(Card);

import React from "react";
import { DragSource, DropTarget } from "react-dnd";
import cn from "classnames";
import _ from "lodash";
import { XCircleIcon, PlusCircleIcon } from "@heroicons/react/outline";

export function Card(props) {
  return _.flowRight(
    props.connectDragSource,
    props.connectDropTarget
  )(
    <div
      className={cn(
        "flex justify-between py-4 px-2 bg-gray-100 dark:bg-gray-600 rounded-md cursor-pointer border-2 border-gray-100 dark:border-gray-600",
        {
          "opacity-70": props.isDragging,
          "opacity-0": props.isSpacer,
          "line-through opacity-50": props.deletable,
        }
      )}
    >
      <div className="flex justify-between px-2">{props.title}</div>
      {props.deletable ? (
        <PlusCircleIcon
          onClick={props.deleteCard}
          className="w-7 cursor-pointer text-pink-500 opacity-85"
        ></PlusCircleIcon>
      ) : (
        <XCircleIcon
          onClick={props.deleteCard}
          className="w-7 cursor-pointer text-pink-500 opacity-85"
        ></XCircleIcon>
      )}
    </div>
  );
}

export const DraggableCard = _.flowRight([
  DropTarget(
    "Card",
    {
      hover(props, monitor) {
        const { columnId, columnIndex } = props;
        const draggingItem = monitor.getItem();
        if (draggingItem.id !== props._id) {
          props.moveCard(draggingItem.id, columnId, columnIndex);
        }
      },
    },
    (connect) => ({
      connectDropTarget: connect.dropTarget(),
    })
  ),
  DragSource(
    "Card",
    {
      beginDrag(props) {
        return { id: props._id };
      },

      isDragging(props, monitor) {
        return props._id === monitor.getItem().id;
      },
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  ),
])(Card);
