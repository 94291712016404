import React from "react";
import CategoryDnD from "./CategoryDnD";

export default function CategoryEdit() {
  return (
    <>
      <h2 className="ml-10 lg:ml-0 mb-4 text-xl">Редактор категорий товаров</h2>
      <CategoryDnD />
    </>
  );
}
