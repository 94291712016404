import React, { useEffect, useState } from "react";
import {
  XIcon,
  MinusIcon,
  PlusIcon,
  TruckIcon,
} from "@heroicons/react/outline";
import { AdminContext } from "../../Components/shared/AdminProvider";
import axios from "axios";

export function CartList({ order, setOrder, edit, setEdit }) {
  const changeCart = ({ action, target }) => {
    switch (action) {
      case "INC":
        var incTarget = editedOrder.products.find(({ _id }) => _id === target);
        if (incTarget) {
          incTarget.qty += 1;
          setEdited({
            ...editedOrder,
            products: editedOrder.products.map((value) =>
              value === target ? incTarget : value
            ),
          });
        }

        break;
      case "DEC":
        var decTarget = editedOrder.products.find(({ _id }) => _id === target);
        if (decTarget) {
          decTarget.qty -= 1;
          if (decTarget.qty > 0)
            setEdited({
              ...editedOrder,
              products: editedOrder.products.map((value) =>
                value._id === target ? decTarget : value
              ),
            });
          else
            setEdited({
              ...editedOrder,
              products: editedOrder.products.filter(
                (value) => value._id !== target
              ),
            });
        }
        break;
      case "ADD":
        var addTarget = target;
        if (addTarget) {
          setEdited({
            ...editedOrder,
            products: [...editedOrder.products, addTarget],
          });
        }
        break;
      case "DELETE":
        if (target)
          setEdited({
            ...editedOrder,
            products: editedOrder.products.filter(
              (value) => value._id !== target
            ),
          });
        break;
      default:
        break;
    }
  };
  const [editedOrder, setEdited] = useState("");
  const [isChanging, setChanging] = React.useContext(AdminContext).changing;

  useEffect(() => {
    if (edit) {
      setEdited(JSON.parse(JSON.stringify(order)));
    }
  }, [edit]);

  function cancelEdit() {
    setEdit(false);
    setEdited(false);
  }

  async function confirmEdit() {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };
    await axios
      .put(
        `${process.env.REACT_APP_SERVER}/api/v2/orders/${order._id}`,
        editedOrder,
        config
      )
      .then((response) => {
        setOrder(JSON.parse(JSON.stringify(editedOrder)));
        console.log(order);
      })
      .catch((err) => {
        console.log(err);
      });
    setEdit(false);
    setEdited(false);
  }

  return order.products.length ? (
    <form>
      <ul className="flex w-full items-stretch flex-col">
        {!editedOrder
          ? order.products.map((product) => {
              return (
                <ProductTile
                  product={product}
                  edit={edit}
                  changeCart={changeCart}
                />
              );
            })
          : editedOrder.products.map((product) => {
              return (
                <ProductTile
                  product={product}
                  edit={edit}
                  changeCart={changeCart}
                />
              );
            })}
        {order.isShipping ? (
          <li className="border-b border-gray-400 px-5 py-5 flex flex-row justify-between capitalize text-xl">
            <span className="flex flex-row items-center gap-2">
              <TruckIcon className="h-5" />
              Доставка:
            </span>
            {editedOrder ? (
              <input
                type="number"
                id="delivery"
                placeholder="0"
                min={0}
                className="mr-2 w-20 py-2 px-3 text-right bg-white rounded-lg shadow-md cursor-pointer focus:outline-none sm:text-sm ring-2 ring-white focus:ring-gray-300 dark:bg-gray-700 dark:ring-gray-700 dark:focus:ring-gray-600"
                value={editedOrder.delivery}
                onChange={(e) => {
                  e.preventDefault();
                  setEdited({
                    ...editedOrder,
                    delivery: Number(e.target.value),
                  });
                }}
                name="delivery"
              />
            ) : (
              <span>
                {order.delivery} {isNaN(order.delivery) ? "" : "₽"}
              </span>
            )}
          </li>
        ) : null}
      </ul>
      {!editedOrder ? (
        <div className="capitalize text-xl ml-auto mr-5 w-fit whitespace-nowrap my-5">
          <span className="mr-5 font-semibold">Итого:</span>
          {order.delivery
            ? order.totalSum + (isNaN(order.delivery) ? 0 : order.delivery)
            : order.totalSum}{" "}
          ₽
        </div>
      ) : (
        <>
          <div className="capitalize text-xl ml-auto mr-5 w-fit whitespace-nowrap my-5">
            <span className="mr-5 font-semibold">Итого:</span>
            <input
              type="number"
              id="price"
              placeholder="0"
              min={0}
              className="mr-2 w-20 py-2 px-3 text-right bg-white rounded-lg shadow-md cursor-pointer focus:outline-none sm:text-sm ring-2 ring-white focus:ring-gray-300 dark:bg-gray-700 dark:ring-gray-700 dark:focus:ring-gray-600"
              value={editedOrder.totalSum}
              onChange={(e) => {
                e.preventDefault();
                setEdited({ ...editedOrder, totalSum: Number(e.target.value) });
              }}
              name="price"
            />
            ₽
          </div>
          <div className="flex gap-3 my-4 ml-auto w-fit">
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                cancelEdit();
              }}
              className="inline-flex justify-center rounded-md border border-transparent bg-red-500/95 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            >
              Отменить
            </button>
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                confirmEdit();
              }}
              className="inline-flex justify-center rounded-md border border-transparent bg-accent/95 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            >
              Сохранить
            </button>
          </div>
        </>
      )}
    </form>
  ) : (
    <p className="italic text-lg">Пусто</p>
  );
}

function ProductTile({ product, changeCart, edit }) {
  return (
    <li className="border-b border-gray-400 px-5">
      <div className="flex w-full flex-row flex-nowrap justify-between gap-6">
        <a
          className="relative py-7"
          href={process.env.REACT_APP_SITE + `/product/${product._id}`}
          target="_blank"
          rel="noreferrer"
        >
          <h4 className="text-lg w-full absolute top-0 whitespace-nowrap">
            {product.title}
          </h4>
          <img
            src={product.image}
            alt=""
            className="aspect-1 object-cover w-full max-h-40 p-1 min-w-[2.5rem]"
          ></img>
        </a>
        <div className="flex flex-row-reverse gap-4 md:flex-col md:gap-2 items-center my-auto w-full">
          <button
            disabled={!edit}
            className="disabled:opacity-10 enabled:hover:opacity-70 enabled:hover:cursor-pointer enabled:text-accent"
            onClick={(e) => {
              e.preventDefault();
              changeCart({ action: "INC", target: product._id });
            }}
          >
            <PlusIcon className="w-5 h-5"></PlusIcon>
          </button>
          <span className="">{product.qty}</span>
          <button
            disabled={!edit}
            className="disabled:opacity-10 enabled:hover:opacity-70 enabled:hover:cursor-pointer enabled:text-accent"
            onClick={(e) => {
              e.preventDefault();
              changeCart({ action: "DEC", target: product._id });
            }}
          >
            <MinusIcon className="w-5 h-5"></MinusIcon>
          </button>
        </div>
        <span className="capitalize text-xl w-full max-w-[15%] text-right my-auto whitespace-nowrap ">
          {product.price * product.qty} ₽
        </span>

        <button
          disabled={!edit}
          className="disabled:opacity-10 enabled:hover:opacity-70 enabled:hover:cursor-pointer enabled:text-red-600"
          onClick={(e) => {
            e.preventDefault();
            changeCart({ action: "DELETE", target: product._id });
          }}
        >
          <XIcon className="w-5 h-5"></XIcon>
        </button>
      </div>
    </li>
  );
}
