import React, { useEffect, useState } from "react";
import axios from "axios";
import Table, { SelectColumnFilter } from "../../Components/Table";
import { RefreshIcon } from "@heroicons/react/outline";
import defaultImg from "../../img/cropped-favicon-2-1-192x192.png";
import { classNames } from "../../Components/shared/Utils";
import { CheckIcon, EyeIcon, PlusIcon } from "@heroicons/react/solid";
import { Link, useNavigate } from "react-router-dom";
import { AdminContext } from "../../Components/shared/AdminProvider";
import { Convert } from "mongo-image-converter";

function StatusPill({ value }) {
  return (
    <span
      className={classNames(
        "relative uppercase leading-wide font-bold text-xs select-none z-20 before:block before:absolute before:-inset-[0.4rem] before:-z-10 before:rounded-full before:shadow-sm",
        value.startsWith("в продаже")
          ? "before:bg-gray-50 text-accent dark:before:bg-gray-800/80 dark:text-accent"
          : null,
        value.startsWith("скрыто")
          ? "before:bg-red-100 text-red-800 dark:before:bg-red-600/95 dark:text-red-200"
          : null
      )}
    >
      {value}
    </span>
  );
}

function PopularPill({ value }) {
  return (
    <span
      className={classNames(
        "relative uppercase leading-wide font-bold text-xs select-none z-20 before:block before:absolute before:-inset-[0.4rem] before:-z-10 before:rounded-full before:shadow-sm",
        value.startsWith("Да")
          ? "before:bg-gray-50 text-accent dark:before:bg-gray-800/80 dark:text-accent"
          : null
      )}
    >
      {value === "Да" ? "Да" : null}
    </span>
  );
}

function ImgCell({ value, column, row }) {
  return (
    <div className="flex-shrink-0 h-14 w-14">
      <img
        className="h-full w-full rounded-md object-cover object-center"
        src={row.original[column.imgAccessor] || defaultImg}
        alt=""
      />
    </div>
  );
}

function QtyCell({ value }) {
  return value ? (
    <>{value}</>
  ) : (
    <span className="relative uppercase leading-wide font-bold text-xs select-none z-20 before:block before:absolute before:-inset-[0.4rem] before:-z-10 before:rounded-full before:shadow-sm before:bg-yellow-100 text-yellow-800 dark:before:bg-yellow-600/95 dark:text-yellow-50">
      нет в наличии
    </span>
  );
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

function LinkCell({ value }) {
  return (
    <a
      href={`${process.env.REACT_APP_SITE}/product/${value}`}
      className="text-sm font-medium flex flex-row gap-1 hover:text-accent underline z-30"
      target="_blank"
      rel="noreferrer"
      name="link"
    >
      <span name="link">Посмотреть</span> <EyeIcon className="w-4" />
    </a>
  );
}

// function CheckHook(hooks) {
//   hooks.visibleColumns.push((columns) => [
//     // Let's make a column for selection
//     {
//       id: "selection",
//       // The header can use the table's getToggleAllRowsSelectedProps method
//       // to render a checkbox
//       Header: ({ getToggleAllPageRowsSelectedProps }) => (
//         <div>
//           <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
//         </div>
//       ),
//       // The cell can use the individual row's getToggleRowSelectedProps method
//       // to the render a checkbox
//       Cell: ({ row }) => (
//         <div>
//           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
//         </div>
//       ),
//     },
//     ...columns,
//   ]);
// }

export const ProductList = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: " ",
        Cell: ImgCell,
        imgAccessor: "image",
      },
      {
        Header: "Название",
        accessor: "title",
      },
      {
        Header: "Цена",
        accessor: "price",
      },
      {
        Header: "Категория",
        accessor: "category",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Статус",
        accessor: "isShown",
        Cell: StatusPill,
        Filter: SelectColumnFilter,
      },
      {
        Header: "Кол-во",
        accessor: "qty",
        Cell: QtyCell,
        Filter: SelectColumnFilter,
      },
      {
        Header: "Поп?",
        accessor: "popular",
        Cell: PopularPill,
        Filter: SelectColumnFilter,
      },
      {
        Header: "Ссылки",
        accessor: "_id",
        Cell: LinkCell,
        disableSortBy: true,
      },
    ],
    []
  );

  const [error, setError] = useState("");
  const [privateData, setPrivateData] = useState("");
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    const fetchPrivateData = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };

      axios
        .get(
          process.env.REACT_APP_SERVER + "/api/v2/products/protected",
          config,
          {}
        )
        .then((response) => {
          setPrivateData(
            response.data.data.map((value) => {
              const newValue = value;
              newValue.isShown = value.isShown ? "в продаже" : "скрыто";
              newValue.popular = value.popular ? "Да" : "Нет";
              return newValue;
            })
          );
        })
        .catch((err) => {
          setError(err.response.data.error);
        });
    };

    fetchPrivateData();
  }, []);

  let navigate = useNavigate();
  const setProduct = ({ _id }, name) => {
    if (name !== "link") window.open("/productList/" + _id, "_blank");
  };

  const { productState } = React.useContext(AdminContext);

  return (
    <>
      <h2 className="ml-10 lg:ml-0 mb-4 text-xl">Список товаров</h2>
      {error ? (
        <span className="error-message">{error}</span>
      ) : privateData ? (
        <>
          <Table
            columns={columns}
            data={privateData}
            setItem={setProduct}
            initialState={productState}
          />
          <Link
            to="/add"
            className="bg-white p-2 rounded-md text-pink-500 block w-fit"
          >
            <PlusIcon className="w-7 h-7" />
          </Link>
        </>
      ) : (
        <span className="animate-spin absolute">
          <RefreshIcon className="w-7 h-7 text-accent "></RefreshIcon>
        </span>
      )}
    </>
  );
};
