import React, { useState, Fragment } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  TruckIcon,
  CollectionIcon,
  ShoppingCartIcon,
  CubeIcon,
  PlusIcon,
  ColorSwatchIcon,
  SunIcon,
  MoonIcon,
  MenuAlt2Icon,
  XIcon,
} from "@heroicons/react/outline";
import { classNames } from "./shared/Utils";
import { AdminContext } from "./shared/AdminProvider";
import { Dialog, Transition } from "@headlessui/react";

const navTabs = [
  { name: "Карточки", url: "/cardlist" },
  { name: "Заказы", url: "/orders" },
  { name: "Категории", url: "/categories" },
  { name: "Товары", url: "/productlist" },
  { name: "Добавить", url: "/add" },
];

export const SideBar = ({ page }) => {
  const dark = <SunIcon className="h-6 w-6" />;
  const light = <MoonIcon className="h-6 w-6" />;
  const colorTheme =
    localStorage.getItem("color-theme") === "dark" ? dark : light;
  const [theme, setTheme] = useState(colorTheme);
  const [isOpen, setOpen] = useState(false);
  const [isChanging, setChanging] = React.useContext(AdminContext).changing;
  let [modal, setModal] = useState({ open: false, link: "" });
  let navigate = useNavigate();

  const cancelModal = () => {
    setModal({ open: false, link: "" });
  };

  const submitModal = () => {
    navigate(modal.link);
    setModal({ open: false, link: "" });
    setChanging(false);
  };

  const handleClick = (e) => {
    if (isChanging) {
      e.preventDefault();
      setModal({ open: true, link: e.target.href.split("3000")[1] });
    }
    setOpen(false);
  };

  return (
    <>
      <Transition appear show={modal.open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={cancelModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-semibold leading-6 text-red-600"
                  >
                    Не сохраненные данные
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-gray-500">
                      Вы уверены, что хотите покинуть страницу?
                    </p>
                  </div>

                  <div className="mt-4 flex justify-between">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={cancelModal}
                    >
                      Отмена
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-semibold text-red-100 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={submitModal}
                    >
                      Выйти
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <button
        type="button"
        className="absolute lg:hidden top-4 left-3 z-50 dark:text-gray-100"
        onClick={(e) => {
          e.preventDefault();
          setOpen(!isOpen);
        }}
      >
        {!isOpen ? (
          <MenuAlt2Icon className="w-7 h-7" />
        ) : (
          <XIcon className="w-7 h-7" />
        )}
      </button>
      <div
        className={`bg-white z-40 h-screen fixed lg:block lg:w-52 left-0 top-0 px-4 border border-gray-300 text-lg text-gray-700 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-900 ${
          isOpen ? "" : "hidden"
        }`}
      >
        <NavLink
          to="/"
          className="py-3 border-b border-gray-300 mb-2 flex flex-row gap-2 items-center justify-center select-none"
          onClick={handleClick}
        >
          <CubeIcon className="h-14" />
          <h1 className="leading-snug font-semibold basis-0">
            LaFlorida Admin
          </h1>
        </NavLink>
        <nav className="flex flex-col gap-1">
          {navTabs.map((navTab) => (
            <NavLink
              to={navTab.url}
              key={navTab.url.slice(1)}
              onClick={handleClick}
              className={classNames(
                "py-3 px-5 rounded-md flex flex-row items-center gap-2",
                page === navTab.name
                  ? "bg-gray-100 shadow-md hover:text-gray-500 dark:bg-gray-600 dark:hover:text-gray-400"
                  : "hover:text-gray-400"
              )}
            >
              <span className="h-6 w-6">
                {
                  {
                    Карточки: <ColorSwatchIcon />,
                    Заказы: <TruckIcon />,
                    Категории: <CollectionIcon />,
                    Товары: <ShoppingCartIcon />,
                    Добавить: <PlusIcon />,
                  }[navTab.name]
                }
              </span>
              {navTab.name}
            </NavLink>
          ))}
        </nav>
        <button
          id="theme-toggle"
          type="button"
          className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-600 rounded-lg text-sm p-2.5 ml-2 mt-4 flex flex-row justify-center items-center gap-1"
          onClick={() => {
            if (localStorage.getItem("color-theme") === "dark") {
              localStorage.setItem("color-theme", "light");
              document.documentElement.classList.remove("dark");
              setTheme(light);
            } else {
              localStorage.setItem("color-theme", "dark");
              document.documentElement.classList.add("dark");
              setTheme(dark);
            }
          }}
        >
          {theme} Тема
        </button>
      </div>
    </>
  );
};
