import React, { useState, useEffect, Fragment } from "react";
import { MyListbox, ReactTagsDemo } from "./FormComponenets";
import { Convert } from "mongo-image-converter";
import { useNavigate, useParams } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/solid";
import axios from "axios";
import { TrashIcon, ChevronLeftIcon } from "@heroicons/react/solid";
import { Dialog, Transition } from "@headlessui/react";
import { ImgInput } from "../../Components/FormComponents";

export default function EditProduct() {
  let params = useParams();
  let navigate = useNavigate();
  const productId = params.productId;
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [isEdited, setEdited] = useState(false);
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function returnBack(e) {
    e.preventDefault();
    navigate("/productList", { replace: true });
  }

  const [info, setInfo] = useState({
    title: "",
    description: "",
    price: "",
    qty: "",
    category: "",
    isShown: "",
    composition: [],
    image: "",
    popular: "",
  });
  const saveProductHandler = async (e) => {
    e.preventDefault();
    try {
      // const convertedImage = await Convert(info.image);
      // if (convertedImage) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };
      const request = {
        ...info,
        composition: info.composition.map(({ text }) => {
          return text;
        }),
        category: info.category,
      };

      axios
        .put(
          `${process.env.REACT_APP_SERVER}/api/v2/products/${productId}`,
          request,
          config
        )
        .then((response) => {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            navigate("/productList", { replace: true });
          }, 1000);
        })
        .catch((err) => {
          setError(err.response.data.error);
          setTimeout(() => {
            setError("");
          }, 3000);
        });
      // } else {
      //   console.log("The file is not in format of image/jpeg or image/png");
      // }
    } catch (error) {
      console.warn(error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target ? e.target : e;
    setInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setEdited(true);
  };

  const setImage = async (e) => {
    const convertedImage = await Convert(e);
    setInfo((prevState) => ({
      ...prevState,
      image: convertedImage,
    }));
    setEdited(true);
  };

  const deleteImage = (e) => {
    e.preventDefault();
    setInfo((prevState) => ({
      ...prevState,
      image: "",
    }));
    setEdited(true);
  };

  const deleteProduct = async (e) => {
    e.preventDefault();
    try {
      // const convertedImage = await Convert(info.image);
      // if (convertedImage) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };

      axios
        .delete(`${process.env.REACT_APP_SERVER}/api/v2/products`, {
          ...config,
          data: { deletable: { _id: productId } },
        })
        .then((response) => {
          // setSuccess(true);
          // setTimeout(() => {
          //   setSuccess(false);
          //   navigate("/productList", { replace: true });
          // }, 1000);
          navigate("/productList", { replace: true });
        })
        .catch((err) => {
          setError(err.response.data.error);
          setTimeout(() => {
            setError("");
          }, 3000);
        });
      // } else {
      //   console.log("The file is not in format of image/jpeg or image/png");
      // }
    } catch (error) {
      console.warn(error.message);
    }
  };

  useEffect(() => {
    const fetchPrivateData = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };

      axios
        .get(
          `${process.env.REACT_APP_SERVER}/api/v2/products/${productId}/protected`,
          config
        )
        .then((response) => {
          const data = response.data;
          let newInfo = {};
          for (const [key, value] of Object.entries(info)) {
            newInfo[key] = data[key];
          }
          newInfo.composition = newInfo.composition.map((value) => {
            return {
              id: value.toLowerCase(),
              text: value,
            };
          });
          setInfo(newInfo);
          console.log(newInfo);
        })
        .catch((err) => {
          setError(err.response.data.error);
        });
    };

    fetchPrivateData();
  }, []);

  return (
    <>
      <div className="ml-10 lg:ml-0 mb-4 relative">
        <button
          type="button"
          onClick={returnBack}
          className="absolute -left-[2rem]"
        >
          <ChevronLeftIcon className="w-8 h-8"></ChevronLeftIcon>
        </button>

        <h2 className="text-xl">Товар {info.title}</h2>
      </div>
      {error ? (
        <span className="error-message">{error}</span>
      ) : success ? (
        <div className="flex f-full h-1/3 flex-row w-full gap-2 justify-center items-center">
          <CheckIcon className="w-7 h-7 text-accent" />
          <p className="text-xl font-bold text-accent">Изменено!</p>
        </div>
      ) : (
        <>
          <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-40" onClose={closeModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                      <Dialog.Title
                        as="h3"
                        className="text-xl font-semibold leading-6 text-red-600"
                      >
                        Удаление позиции
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-gray-500">
                          Вы уверены, что хотите удалить эту позицию?
                        </p>
                      </div>

                      <div className="mt-4 flex justify-between">
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          onClick={closeModal}
                        >
                          Отмена
                        </button>
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-semibold text-red-100 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          onClick={deleteProduct}
                        >
                          Удалить
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
          <form
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 items-start relative pb-20 mb-20"
            method="POST"
            onSubmit={saveProductHandler}
          >
            <label htmlFor="title">
              Название
              <input
                type="text"
                id="title"
                placeholder="Название"
                className="mt-1 relative w-full py-2 px-3 text-left bg-white rounded-lg shadow-md focus:outline-none sm:text-sm ring-2 ring-white focus:ring-gray-300 dark:bg-gray-700 dark:ring-gray-700 dark:focus:ring-gray-600 cursor-pointer"
                required
                value={info.title}
                onChange={handleChange}
                name="title"
              ></input>
            </label>
            <label htmlFor="category" className="z-20">
              Категория
              {info.category && (
                <MyListbox
                  id="category"
                  selected={info.category}
                  setSelected={handleChange}
                  name="category"
                />
              )}
            </label>
            <div className="flex justify-between">
              <label htmlFor="price" className="flex flex-col">
                Цена
                <input
                  type="number"
                  id="price"
                  placeholder="0"
                  min={0}
                  className="relative mt-1 w-20 py-2 px-3 text-left bg-white rounded-lg shadow-md cursor-pointer focus:outline-none sm:text-sm ring-2 ring-white focus:ring-gray-300 dark:bg-gray-700 dark:ring-gray-700 dark:focus:ring-gray-600"
                  value={info.price}
                  onChange={handleChange}
                  name="price"
                />
              </label>
              <label htmlFor="qty" className="flex flex-col">
                Кол-во
                <input
                  type="number"
                  id="qty"
                  placeholder="0"
                  min={0}
                  className="relative mt-1 w-20 py-2 px-3 text-left bg-white rounded-lg shadow-md cursor-pointer focus:outline-none sm:text-sm ring-2 ring-white focus:ring-gray-300 dark:bg-gray-700 dark:ring-gray-700 dark:focus:ring-gray-600"
                  value={info.qty}
                  onChange={handleChange}
                  name="qty"
                />
              </label>
              <div className="flex flex-col gap-3">
                <label
                  htmlFor="isShown"
                  className="z-20 flex flex-row gap-1 items-center"
                >
                  Скрыть товар
                  <input
                    type="checkbox"
                    id="isShown"
                    checked={!info.isShown}
                    onChange={(e) => {
                      handleChange({
                        name: "isShown",
                        value: !e.target.checked,
                      });
                    }}
                    name="isShown"
                    className="w-5 h-5 mt-2 cursor-pointer"
                  />
                </label>
                <label
                  htmlFor="popular"
                  className="z-20 flex flex-row gap-1 items-center"
                >
                  Популярный
                  <input
                    type="checkbox"
                    id="popular"
                    checked={info.popular}
                    onChange={(e) => {
                      handleChange({
                        name: "popular",
                        value: e.target.checked,
                      });
                    }}
                    name="popular"
                    className="w-5 h-5 mt-2 cursor-pointer"
                  />
                </label>
              </div>
            </div>
            <label className="relative" for="">
              Изображение
              <div className="aspect-1">
                <ImgInput
                  image={info.image}
                  deleteImage={deleteImage}
                  setImage={setImage}
                />
              </div>
            </label>
            <label htmlFor="description">
              Описание
              <textarea
                type="textarea"
                id="description"
                placeholder="Описание..."
                rows="5"
                className="mt-1 placeholder:italic resize-none relative w-full py-2 px-3 text-left bg-white rounded-lg shadow-md cursor-pointer focus:outline-none sm:text-sm ring-2 ring-white focus:ring-gray-300 dark:bg-gray-700 dark:ring-gray-700 dark:focus:ring-gray-600"
                value={info.description}
                onChange={handleChange}
                name="description"
              ></textarea>
            </label>
            <label htmlFor="composition" className="z-20">
              Состав
              <ReactTagsDemo
                id="composition"
                tags={info.composition}
                setTags={handleChange}
                name="composition"
              />
            </label>
            <button
              type="button"
              onClick={openModal}
              className="rounded-md bg-opacity-20 px-2 py-2 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 bg-red-500 text-red-800 dark:bg-red-600/90 dark:text-red-200 absolute bottom-0 left-0"
            >
              <TrashIcon className="w-7 h-7"></TrashIcon>
            </button>
            <button
              type="submit"
              className="bg-accent text-lg text-white py-2 px-4 w-fit rounded-lg border-none absolute bottom-0 right-0 hover:opacity-80 disabled:hover:opacity-100 disabled:bg-gray-500"
              disabled={!isEdited}
            >
              Сохранить
            </button>
          </form>
        </>
      )}
    </>
  );
}
