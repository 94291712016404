import React, { Component } from "react";

export class TextForm extends Component {
  onSubmit = (event) => {
    const form = event.target;
    event.preventDefault();
    this.props.onSubmit(form.input.value);
    form.reset();
  };

  render() {
    return (
      <form
        onSubmit={this.onSubmit}
        ref={(node) => (this.form = node)}
        className=""
      >
        <input
          type="text"
          className="rounded-md p-2 border-2 dark:border-gray-600 text-gray-600"
          name="input"
          placeholder={this.props.placeholder}
        />
      </form>
    );
  }
}
