import React from "react";
import { TextForm } from "./TextForm";
import { XCircleIcon, PlusCircleIcon } from "@heroicons/react/outline";
import { ImgInput } from "../../../Components/FormComponents";

export function Column(props) {
  return (
    <div
      className={`bg-white dark:bg-gray-700 rounded-lg shadow-md p-3 flex flex-col gap-2 min-w-64 max-w-xs items-stretch ${
        props.deletable ? "opacity-50" : ""
      }`}
    >
      <div
        className={` text-lg font-semibold mb-3 grid grid-flow-row grid-cols-6 justify-items-center gap-2 items-center px-2 ${
          props.deletable ? "line-through" : ""
        }`}
      >
        <div className="col-span-2 justify-self-start w-[4.25rem] h-[4.25rem]">
          <ImgInput
            setImage={props.setImage}
            deleteImage={props.deleteImage}
            image={props.image}
            id={props.id}
          />
        </div>
        <span className="col-span-3">{props.title}</span>
        {props.deletable ? (
          <PlusCircleIcon
            onClick={props.deleteColumn}
            className="w-7 cursor-pointer text-pink-500 opacity-85"
          ></PlusCircleIcon>
        ) : (
          <XCircleIcon
            onClick={props.deleteColumn}
            className="w-7 cursor-pointer text-pink-500 opacity-85"
          ></XCircleIcon>
        )}
      </div>
      <DescForm value={props.desc} handleChange={props.changeDesc} />
      {props.children}
      <TextForm
        onSubmit={props.addCard}
        placeholder="Добавить подкатегорию..."
      />
    </div>
  );
}

function DescForm({ value, handleChange }) {
  return (
    <textarea
      type="textarea"
      id="description"
      placeholder="Описание..."
      rows="5"
      className="mt-1 placeholder:italic resize-none relative w-full py-2 px-3 text-left rounded-md bg-white cursor-pointer focus:outline-none sm:text-sm ring-1 ring-white focus:ring-gray-300 dark:bg-gray-700 dark:ring-gray-700 dark:focus:ring-gray-600"
      required
      value={value}
      onChange={handleChange}
      name="description"
    ></textarea>
  );
}
