import React, { useState, useEffect } from "react";
import axios from "axios";
import { XIcon } from "@heroicons/react/solid";
import { Convert } from "mongo-image-converter";
import { WithContext as ReactTags } from "react-tag-input";
import styles from "../AddProduct/ReactTags.css";
import { ImgInput } from "../../Components/FormComponents";
import { AdminContext } from "../../Components/shared/AdminProvider";

export function CardView({ card, setCard }) {
  const closeView = (e) => {
    e.preventDefault();
    setCard("");
    setChanging(false);
  };

  const removeProduct = (e, id) => {
    e.preventDefault();
    setCard((prevState) => ({
      ...prevState,
      productList: prevState.productList.filter((value) => value !== id),
    }));
    setChanging(true);
  };

  const [isEdited, setEdited] = useState(false);
  const [data, setData] = useState("");
  const [error, setError] = useState("");
  const [isChanging, setChanging] = React.useContext(AdminContext).changing;

  const handleChange = (e) => {
    const { name, value } = e.target ? e.target : e;
    setCard((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setEdited(true);
    setChanging(true);
  };

  const setImage = async (e) => {
    const convertedImage = await Convert(e);
    setCard((prevState) => ({
      ...prevState,
      image: convertedImage,
    }));
    setEdited(true);
    setChanging(true);
  };

  const deleteImage = (e) => {
    e.preventDefault();
    setCard((prevState) => ({
      ...prevState,
      image: "",
    }));
    setEdited(true);
    setChanging(true);
  };

  const saveProductHandler = async (e) => {
    e.preventDefault();
    try {
      // const convertedImage = await Convert(info.image);
      // if (convertedImage) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };

      card._id
        ? axios
            .put(
              `${process.env.REACT_APP_SERVER}/api/v2/cards/${card._id}`,
              card,
              config
            )
            .then((response) => {
              setCard("");
            })
            .catch((err) => {
              setError(err.response.data.error);
              setTimeout(() => {
                setError("");
              }, 3000);
            })
        : axios
            .post(process.env.REACT_APP_SERVER + "/api/v2/cards", card, config)
            .then((response) => {
              setCard("");
            })
            .catch((err) => {
              setError(err.response.data.error);
              setTimeout(() => {
                setError("");
              }, 3000);
            });
      // } else {
      //   console.log("The file is not in format of image/jpeg or image/png");
      // }
    } catch (error) {
      console.warn(error.message);
    }
    setChanging(false);
  };

  useEffect(() => {
    const fetchPrivateData = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };

      axios
        .get(
          process.env.REACT_APP_SERVER + "/api/v2/products/protected",
          config,
          {}
        )
        .then((response) => {
          setData(response.data.data);
        })
        .catch((err) => {
          setError(err.response.data.error);
        });
    };

    fetchPrivateData();
  }, [card.productList]);

  return (
    <div className="fixed top-0 right-0 w-full ml-3 z-30 md:w-1/2 lg:w-1/3 h-full overflow-y-scroll">
      <div className=" min-h-screen px-12 bg-white shadow-lg text-gray-700 dark:bg-gray-700 dark:text-gray-200 dark:bcard-gray-900 py-4 md:px-5">
        <h2 className="text-2xl font-semibold w-10/12">Карточка №{card._id}</h2>
        <button
          type="button"
          onClick={closeView}
          className="absolute right-5 top-4"
        >
          <XIcon className="w-8 h-8" />
        </button>
        <form
          className="flex flex-col gap-5 p-2"
          method="POST"
          onSubmit={saveProductHandler}
        >
          <label htmlFor="isShown" className="flex items-center gap-2">
            <span>Название: </span>
            <input
              type="text"
              id="title"
              placeholder="Название"
              className="mt-1 relative w-full py-2 px-3 text-left bg-white rounded-lg shadow-md focus:outline-none sm:text-sm ring-2 ring-gray-100 focus:ring-gray-300 dark:bg-gray-700 dark:ring-gray-700 dark:focus:ring-gray-600 cursor-pointer"
              required
              value={card.title}
              onChange={handleChange}
              name="title"
            />
          </label>
          <div className="flex items-center justify-between">
            <label htmlFor="isShown" className="flex items-center gap-2">
              Скрыть карточку:
              <br />
              <input
                type="checkbox"
                id="isShown"
                checked={!card.isShown}
                onChange={(e) => {
                  handleChange({ name: "isShown", value: !e.target.checked });
                }}
                name="isShown"
                className="w-5 h-5 mt-2 cursor-pointer"
              />
            </label>
            <label htmlFor="index" className="flex items-center gap-2">
              Индекс:
              <input
                type="number"
                id="index"
                placeholder="0"
                min={0}
                className="relative mt-1 w-20 py-2 px-3 text-left bg-white rounded-lg shadow-md cursor-pointer focus:outline-none sm:text-sm ring-2 ring-gray-100 focus:ring-gray-300 dark:bg-gray-700 dark:ring-gray-700 dark:focus:ring-gray-600"
                value={card.index}
                onChange={handleChange}
                name="index"
              />
            </label>
          </div>
          <label className="relative flex gap-4 w-fit" for="image">
            Изображение:
            <div className="min-w-[10rem] min-h-[10rem]">
              <ImgInput
                image={card.image}
                setImage={setImage}
                deleteImage={deleteImage}
              />
            </div>
          </label>
          <label htmlFor="description">
            Описание
            <textarea
              type="textarea"
              id="description"
              placeholder="Описание..."
              rows="5"
              className="mt-1 placeholder:italic resize-none relative w-full py-2 px-3 text-left bg-white rounded-lg shadow-md cursor-pointer focus:outline-none sm:text-sm ring-2 ring-white focus:ring-gray-300 dark:bg-gray-700 dark:ring-gray-700 dark:focus:ring-gray-600"
              required
              value={card.description}
              onChange={handleChange}
              name="description"
            ></textarea>
          </label>
          {card.isCustomLink !== undefined ? (
            <label htmlFor="isShown" className="flex items-center gap-2">
              Ссылка?:
              <br />
              <input
                type="checkbox"
                id="isCustomLink"
                checked={card.isCustomLink}
                onChange={(e) => {
                  handleChange({
                    name: "isCustomLink",
                    value: e.target.checked,
                  });
                }}
                name="isCustomLink"
                className="w-5 h-5 mt-2 cursor-pointer"
              />
            </label>
          ) : null}
          {card.isCustomLink ? (
            <label htmlFor="customLink" className="flex items-center gap-2">
              <span>Ссылка: </span>
              <input
                type="text"
                id="customLink"
                placeholder="https://site.ru/link"
                className="mt-1 relative w-full py-2 px-3 text-left bg-white rounded-lg shadow-md focus:outline-none sm:text-sm ring-2 ring-gray-100 focus:ring-gray-300 dark:bg-gray-700 dark:ring-gray-700 dark:focus:ring-gray-600 cursor-pointer"
                required
                value={card.customLink}
                onChange={handleChange}
                name="customLink"
              />
            </label>
          ) : (
            <label>
              Товары акции:
              <ol className="pl-4 text-center">
                {data ? (
                  data
                    .filter(({ _id }) => card.productList.includes(_id))
                    .map((product) => {
                      return (
                        <ProductTile
                          product={product}
                          removeProduct={removeProduct}
                          key={product._id}
                        ></ProductTile>
                      );
                    })
                ) : (
                  <span>Товары не добавлены</span>
                )}
              </ol>
              {data && (
                <ReactTagsDemo
                  data={data}
                  products={card.productList}
                  setProducts={handleChange}
                ></ReactTagsDemo>
              )}
            </label>
          )}
          <button
            type="submit"
            className="bg-accent text-lg text-white py-2 px-4 w-fit rounded-lg border-none self-center hover:opacity-80 disabled:hover:opacity-100 disabled:bg-gray-500"
            disabled={!isEdited}
          >
            Сохранить
          </button>
        </form>
      </div>
    </div>
  );
}

function ProductTile({ product, removeProduct }) {
  return (
    <li className="list-item list-decimal list-outside border-b py-3 border-gray-400">
      <div className="grid grid-cols-[fit-content(5rem)_1fr] justify-items-start gap-2 relative">
        <img
          src={product.image}
          alt=""
          className="w-20 h-20 row-span-3 shrink object-contain"
        ></img>
        <a
          href={`${process.env.REACT_APP_SITE_URL}/product/${product._id}`}
          target="blank"
          className="text-lg font-bold"
        >
          {product.title}
        </a>
        <span>
          <span>Категория: </span>
          <span className="capitalize">{product.category}</span>
        </span>
        <button
          type="button"
          className="absolute right-5 top-4"
          onClick={(e) => removeProduct(e, product._id)}
        >
          <XIcon className="w-5 h-5"></XIcon>
        </button>
      </div>
    </li>
  );
}

const KeyCodes = {
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

function ReactTagsDemo({ data, products, setProducts }) {
  const tagList = data.map(({ _id, title }) => {
    return { id: _id, text: title };
  });
  const [tags, setTags] = useState("");
  const handleDelete = (i) => {
    setProducts({
      name: "productList",
      value: tags.filter((tag, index) => index !== i),
    });
  };

  const handleAddition = (tag) => {
    setProducts({
      name: "productList",
      value: [...products, tag.id],
    });
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = [...products].slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag.id);

    setProducts({ name: "productList", value: newTags });
  };

  const onClearAll = () => {
    setProducts({ name: "productList", value: [] });
  };

  const onTagUpdate = (i, newTag) => {
    const updatedTags = products.slice();
    updatedTags.splice(i, 1, newTag.id);
    setProducts({ name: "productList", value: updatedTags });
  };

  useEffect(() => {
    setTags((tags) => tagList.filter(({ _id }) => products.includes(_id)));
  }, [products]);

  return (
    <div
      className={styles.ReactTags + "ring-3 ring-gray-100 focus:ring-gray-300"}
    >
      {tags && (
        <ReactTags
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleDrag={handleDrag}
          delimiters={delimiters}
          onClearAll={onClearAll}
          onTagUpdate={onTagUpdate}
          suggestions={tagList}
          placeholder="Поиск..."
          minQueryLength={1}
          maxLength={30}
          autofocus={false}
          allowDeleteFromEmptyInput={true}
          autocomplete={true}
          readOnly={false}
          allowUnique={true}
          allowDragDrop={true}
          inline={true}
          allowAdditionFromPaste={true}
          editable={false}
          clearAll={true}
          tags={tags}
        />
      )}
    </div>
  );
}
