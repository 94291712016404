import React, { useState } from "react";
import { PencilIcon, XIcon } from "@heroicons/react/solid";
import defaultImage from "../img/defaulImage.png";

export function ImgInput({ image, deleteImage, setImage, id }) {
  const [alert, setAlert] = useState(false);
  return (
    <div className="relative w-full h-full">
      {alert ? (
        <div className="absolute bg-red-500 text-white p-1 m-1 rounded-md">
          Слишком большой файл!
        </div>
      ) : null}
      <img
        className="h-full w-full rounded-md object-contain object-center p-2 border-4 border-gray-300 border-dashed dark:border-gray-600 min-h-[3.75rem]"
        src={image || defaultImage}
        alt=""
      />

      <div className="absolute right-[5%] bottom-[5%] flex flex-row gap-1">
        <label
          for={"img" + id}
          className="cursor-pointer rounded-md bg-gray-500 block w-fit text-gray-200  hover:opacity-60"
        >
          <PencilIcon className="w-7 h-7"></PencilIcon>
        </label>
        {image && (
          <button
            className="cursor-pointer rounded-md bg-gray-500 block text-gray-200  hover:opacity-60"
            id="deleteImg"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              deleteImage(id);
            }}
          >
            <XIcon className="w-7 h-7"></XIcon>
          </button>
        )}
      </div>
      <input
        type="file"
        id={"img" + id}
        className="hidden"
        accept=".jpg, .jpeg, .png"
        onChange={(e) => {
          if (e.target.files[0].size > 2097152) {
            setAlert(true);
            setTimeout(() => {
              setAlert(false);
            }, 3500);
          } else setImage(e.target.files[0], id);
        }}
        name="imgUrl"
      ></input>
    </div>
  );
}
