import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginScreen from "./Components/Auth/LoginScreen";
import RegisterScreen from "./Components/Auth/RegisterScreen";
import AddProduct from "./Pages/AddProduct/AddProduct";
import EditProduct from "./Pages/EditProduct/EditProduct";
import { CardList } from "./Pages/CardList/CardList";
import CategoryEdit from "./Pages/CategoryEdit/CategoryEdit";
import Home from "./Pages/Home/Home";
import { OrderList } from "./Pages/OrderList/OrderList";
import { ProductList } from "./Pages/ProductList/ProductList";
import PrivateRoute from "./routing/PrivateRoute";
import AdminProvider from "./Components/shared/AdminProvider";
import { OrderView } from "./Pages/OrderList/OrderView";

const App = () => {
  return (
    <AdminProvider>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute page="/">
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/categories"
          element={
            <PrivateRoute page="Категории">
              <CategoryEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/productlist"
          element={
            <PrivateRoute page="Товары">
              <ProductList />
            </PrivateRoute>
          }
        />
        <Route
          path="/productlist/:productId"
          element={
            <PrivateRoute page="Товары">
              <EditProduct />
            </PrivateRoute>
          }
        />
        <Route
          path="/orders"
          element={
            <PrivateRoute page="Заказы">
              <OrderList />
            </PrivateRoute>
          }
        />
        <Route
          path="/orders/:orderId"
          element={
            <PrivateRoute page="Заказы">
              <OrderView />
            </PrivateRoute>
          }
        />
        <Route
          path="/add"
          element={
            <PrivateRoute page="Добавить">
              <AddProduct />
            </PrivateRoute>
          }
        />
        <Route
          path="/cardlist"
          element={
            <PrivateRoute page="Карточки">
              <CardList />
            </PrivateRoute>
          }
        />
        <Route path="login" element={<LoginScreen />} />
        {/* <Route path="forgotpassword" element={<ForgotPasswordScreen />} /> */}
        <Route path="register" element={<RegisterScreen />} />
        {/* <Route
          path="passwordreset/:resetToken"
          element={<ResetPasswordScreen />}
        /> */}
      </Routes>
    </AdminProvider>
  );
};

export default App;
