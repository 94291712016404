import React, { useState } from "react";
import { MyListbox, ReactTagsDemo } from "./FormComponenets";
import { Convert } from "mongo-image-converter";
import { CheckIcon, RefreshIcon } from "@heroicons/react/solid";
import axios from "axios";
import { ImgInput } from "../../Components/FormComponents";
import { AdminContext } from "../../Components/shared/AdminProvider";

var defaultCategory = { id: 0, name: "Выберите категорию", unavailable: true };

export default function AddProduct() {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [info, setInfo] = useState({
    title: "",
    description: "",
    price: 0,
    qty: 0,
    popular: false,
  });
  const [category, setCategory] = useState(defaultCategory);
  const [isShown, setShow] = useState(true);
  const [composition, setComposition] = useState([]);
  const [imageFile, setImageFile] = useState("");
  const [isChanging, setChanging] = React.useContext(AdminContext).changing;

  const setImage = async (e) => {
    const convertedImage = await Convert(e);
    setImageFile(convertedImage);
    setChanging(true);
  };

  const deleteImage = (e) => {
    e.preventDefault();
    setImageFile("");
    setChanging(true);
  };

  const addProductHandler = async (e) => {
    e.preventDefault();
    setFetching(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };
      const request = {
        ...info,
        image: imageFile,
        category: category.name,
        isShown: isShown,
        composition: composition.map((a) => a.text),
      };

      axios
        .post(
          process.env.REACT_APP_SERVER + "/api/v2/products",
          request,
          config
        )
        .then((response) => {
          setFetching(false);
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 1000);
          setInfo({ title: "", description: "", price: 0 });
          setImageFile("");
        })
        .catch((err) => {
          setFetching(false);
          setError(err.response.data.error);
          setTimeout(() => {
            setError("");
          }, 3000);
        });
      setChanging(false);
    } catch (error) {
      console.warn(error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setChanging(true);
  };

  return (
    <>
      <h2 className="ml-10 lg:ml-0 mb-4 text-xl">Добавление товаров</h2>
      {error ? (
        <span className="error-message">{error}</span>
      ) : fetching ? (
        <div className="flex f-full h-1/3 flex-row w-full gap-2 justify-center items-center">
          <RefreshIcon className="w-7 h-7 text-accent animate-spin" />
          <p className="text-xl font-bold text-accent">Добавление</p>
        </div>
      ) : success ? (
        <div className="flex f-full h-1/3 flex-row w-full gap-2 justify-center items-center">
          <CheckIcon className="w-7 h-7 text-accent" />
          <p className="text-xl font-bold text-accent">Товар добавлен!</p>
        </div>
      ) : (
        <form
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 items-start relative pb-20 mb-20"
          method="POST"
          onSubmit={addProductHandler}
        >
          <label htmlFor="title">
            Название
            <input
              type="text"
              id="title"
              placeholder="Название"
              className="mt-1 capitalize-first relative w-full py-2 px-3 text-left bg-white rounded-lg shadow-md focus:outline-none sm:text-sm ring-2 ring-white focus:ring-gray-300 dark:bg-gray-700 dark:ring-gray-700 dark:focus:ring-gray-600 cursor-pointer"
              required
              value={info.title}
              onChange={handleChange}
              name="title"
            ></input>
          </label>
          <label htmlFor="category" className="z-20">
            Категория
            <MyListbox
              id="category"
              selected={category}
              setSelected={setCategory}
              name="category"
            />
          </label>
          <div className="flex justify-between">
            <label htmlFor="price" className="flex flex-col">
              Цена
              <input
                type="number"
                id="price"
                placeholder="0"
                min={0}
                className="relative mt-1 w-20 py-2 px-3 text-left bg-white rounded-lg shadow-md cursor-pointer focus:outline-none sm:text-sm ring-2 ring-white focus:ring-gray-300 dark:bg-gray-700 dark:ring-gray-700 dark:focus:ring-gray-600"
                value={info.price}
                onChange={handleChange}
                name="price"
              />
            </label>
            <label htmlFor="qty" className="flex flex-col">
              Кол-во
              <input
                type="number"
                id="qty"
                placeholder="0"
                min={0}
                className="relative mt-1 w-20 py-2 px-3 text-left bg-white rounded-lg shadow-md cursor-pointer focus:outline-none sm:text-sm ring-2 ring-white focus:ring-gray-300 dark:bg-gray-700 dark:ring-gray-700 dark:focus:ring-gray-600"
                value={info.qty}
                onChange={handleChange}
                name="qty"
              />
            </label>
            <div className="flex flex-col gap-3">
              <label
                htmlFor="isShown"
                className="z-20 flex flex-row gap-1 items-center"
              >
                Скрыть товар
                <input
                  type="checkbox"
                  id="isShown"
                  checked={!isShown}
                  onChange={(e) => {
                    setShow(!e.target.checked);
                  }}
                  name="isShown"
                  className="w-5 h-5 mt-2 cursor-pointer"
                />
              </label>
              <label
                htmlFor="popular"
                className="z-20 flex flex-row gap-1 items-center"
              >
                Популярный
                <input
                  type="checkbox"
                  id="popular"
                  checked={info.popular}
                  onChange={(e) => {
                    handleChange({
                      target: { name: "popular", value: e.target.checked },
                    });
                  }}
                  name="popular"
                  className="w-5 h-5 mt-2 cursor-pointer"
                />
              </label>
            </div>
          </div>
          <div htmlFor="image">
            Изображение
            <div className="aspect-1">
              <ImgInput
                setImage={setImage}
                deleteImage={deleteImage}
                image={imageFile}
              />
            </div>
            {/* <input
              type="file"
              id="imgUrl"
              className="mt-1 relative w-full py-3 px-3 text-left rounded-lg cursor-default focus:outline-none sm:text-sm border-2 border-gray-300 border-dashed dark:border-gray-600
            file:mr-4 file:py-1 file:px-3
            file:rounded-full file:border-0
            file:text-sm file:font-semibold
            file:bg-accent/10 dark:file:bg-accent/20 file:text-accent
            hover:file:bg-accent/20 dark:hover:file:bg-accent/30 file:cursor-pointer file:transition-colors file:duration-200"
              accept=".jpg, .jpeg, .png"
              onChange={(e) => setImageFile(e.target.files[0])}
              name="imgUrl"
            ></input> */}
          </div>

          <label htmlFor="description">
            Описание
            <textarea
              type="textarea"
              id="description"
              placeholder="Описание..."
              rows="5"
              className="mt-1 placeholder:italic resize-none relative w-full py-2 px-3 text-left bg-white rounded-lg shadow-md cursor-pointer focus:outline-none sm:text-sm ring-2 ring-white focus:ring-gray-300 dark:bg-gray-700 dark:ring-gray-700 dark:focus:ring-gray-600"
              value={info.description}
              onChange={handleChange}
              name="description"
            ></textarea>
          </label>
          <label htmlFor="composition" className="z-20">
            Состав
            <ReactTagsDemo
              id="composition"
              tags={composition}
              setTags={setComposition}
              name="composition"
            />
          </label>
          <button
            type="submit"
            className="bg-accent text-lg text-white py-2 px-4 w-fit rounded-lg border-none absolute bottom-0 right-0 hover:opacity-80"
          >
            Добавить
          </button>
        </form>
      )}
    </>
  );
}
