import React, { useState, useEffect } from "react";
import { classNames } from "../../Components/shared/Utils";
import axios from "axios";
import { CardView } from "./CardView";
import { PlusIcon } from "@heroicons/react/solid";
import { RefreshIcon } from "@heroicons/react/outline";
import Table, { SelectColumnFilter } from "../../Components/Table";

import defaultImg from "../../img/cropped-favicon-2-1-192x192.png";

const defaultCard = {
  title: "",
  image: "",
  productList: [],
  index: "",
  isCustomLink: false,
  customLink: "",
  description: "",
  isShown: false,
};

function StatusPill({ value }) {
  const status = value ? "на сайте" : "скрыто";

  return (
    <span
      className={classNames(
        "relative uppercase leading-wide font-bold text-xs select-none z-20 before:block before:absolute before:-inset-[0.4rem] before:-z-10 before:rounded-full before:shadow-sm",
        status.startsWith("на сайте")
          ? "before:bg-gray-50 text-accent dark:before:bg-gray-800/80 dark:text-accent"
          : null,
        status.startsWith("скрыто")
          ? "before:bg-red-100 text-red-800 dark:before:bg-red-600/95 dark:text-red-200"
          : null
      )}
    >
      {status}
    </span>
  );
}

function ImgCell({ value, column, row }) {
  return (
    <div className="flex-shrink-0 h-14 w-14">
      <img
        className="h-full w-full rounded-md object-cover object-center"
        src={row.original[column.imgAccessor] || defaultImg}
        alt=""
      />
    </div>
  );
}

export const CardList = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: " ",
        Cell: ImgCell,
        imgAccessor: "image",
        disableSortBy: true,
      },
      {
        Header: "Название",
        accessor: "title",
        disableSortBy: true,
      },
      {
        Header: "Статус",
        accessor: "isShown",
        Cell: StatusPill,
        disableSortBy: true,
        Filter: SelectColumnFilter,
      },
    ],
    []
  );
  const [card, setCard] = useState("");
  const [error, setError] = useState("");
  const [privateData, setPrivateData] = useState("");

  useEffect(() => {
    const fetchPrivateData = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };

      axios
        .get(process.env.REACT_APP_SERVER + "/api/v2/cards/protected", config)
        .then((response) => {
          setPrivateData(
            response.data.data.sort((a, b) => (a.index > b.index ? 1 : -1))
          );
        })
        .catch((err) => {
          setError(err.response.data.error);
        });
    };

    fetchPrivateData();
  }, [card]);

  return (
    <>
      <h2 className="ml-10 lg:ml-0 mb-4 text-xl">Список карточек</h2>
      {error ? (
        <div className="flex flex-col gap-4">
          <span className="error-message">{error}</span>
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setCard(defaultCard);
            }}
            className="bg-white p-2 rounded-md text-pink-500 w-fit"
          >
            <PlusIcon className="w-7 h-7" />
          </button>
        </div>
      ) : privateData ? (
        <>
          <Table
            columns={columns}
            data={privateData}
            item={card}
            setItem={setCard}
          />
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setCard(defaultCard);
            }}
            className="bg-white p-2 rounded-md text-pink-500"
          >
            <PlusIcon className="w-7 h-7" />
          </button>
        </>
      ) : (
        <span className="animate-spin absolute">
          <RefreshIcon className="w-7 h-7 text-accent "></RefreshIcon>
        </span>
      )}
      {card && <CardView card={card} setCard={setCard}></CardView>}
    </>
  );
};
