import React, { useEffect, useState } from "react";
import axios from "axios";
import Table, { SelectColumnFilter } from "../../Components/Table";
import { classNames } from "../../Components/shared/Utils";
import { TruckIcon, LocationMarkerIcon } from "@heroicons/react/solid";
import { RefreshIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";

function compare(a, b) {
  const [aString, bString] = [
    !a.deliveryInfo.time.includes("GMT")
      ? a.deliveryInfo.time
      : new Date(a.deliveryInfo.time).getHours() +
        ":" +
        new Date(a.deliveryInfo.time).getMinutes(),
    !b.deliveryInfo.time.includes("GMT")
      ? b.deliveryInfo.time
      : new Date(b.deliveryInfo.time).getHours() +
        ":" +
        new Date(b.deliveryInfo.time).getMinutes(),
  ];

  const str1 = aString.split(":");
  const str2 = bString.split(":");
  const totalSeconds1 = parseInt(str1[0] * 3600 + str1[1].split(" ")[0] * 60);
  const totalSeconds2 = parseInt(str2[0] * 3600 + str2[1].split(" ")[0] * 60);

  if (a.status.localeCompare(b.status, "ru") < 0) return 1;
  if (a.status.localeCompare(b.status, "ru") === 0)
    return new Date(a.deliveryInfo.date).setHours(0, 0, 0, 0) -
      new Date(b.deliveryInfo.date).setHours(0, 0, 0, 0) ===
      0
      ? totalSeconds1 > totalSeconds2
      : new Date(a.deliveryInfo.date).setHours(0, 0, 0, 0) -
          new Date(b.deliveryInfo.date).setHours(0, 0, 0, 0);

  return -1;
}

function StatusPill({ value }) {
  const status = value ? value.toLowerCase() : "unknown";

  return (
    <span
      className={classNames(
        "relative uppercase leading-wide font-bold text-xs select-none z-20 before:block before:absolute before:-inset-[0.4rem] before:-z-10 before:rounded-full before:shadow-sm",
        status.startsWith("завершен")
          ? "before:bg-gray-50 text-gray-800 dark:before:bg-gray-800/80 dark:text-gray-50"
          : null,
        status.startsWith("обработка")
          ? "before:bg-yellow-100 text-yellow-800 dark:before:bg-yellow-600/95 dark:text-yellow-50"
          : null,
        status.startsWith("подтвержден")
          ? "before:bg-accent/10 text-accent"
          : null,
        status.startsWith("не оплачен")
          ? "before:bg-red-100 text-red-800 dark:before:bg-red-600/95 dark:text-red-50"
          : null,
        status.startsWith("отменен")
          ? "before:bg-gray-50 text-gray-800 dark:before:bg-gray-800/80 dark:text-gray-50"
          : null
      )}
    >
      {status}
    </span>
  );
}

function DateCell({ value, column, row }) {
  const date = new Date(value);
  return <span>{date.toLocaleString("ru-RU")}</span>;
}

function TimeCell({ value, column, row }) {
  const date =
    new Date(value.date).toLocaleDateString("ru-RU") +
    " " +
    (value.time.includes("GMT")
      ? new Date(value.time).getHours() +
        ":" +
        new Date(value.time).getMinutes()
      : value.time);
  return <span>{date}</span>;
}

function DeliveryCell({ value }) {
  const delivery = value ? "доставка" : "самовывоз";
  return (
    <span className="flex flex-row gap-2 items-center">
      <span className="uppercase text-sm">{delivery}</span>
      {value ? (
        <TruckIcon className="h-6 w-6 text-gray-500"></TruckIcon>
      ) : (
        <LocationMarkerIcon className="h-6 w-6 text-gray-500"></LocationMarkerIcon>
      )}
    </span>
  );
}

export const OrderList = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: "ФИО",
        accessor: "fullName",
      },
      {
        Header: "Создан",
        accessor: "createdAt",
        Cell: DateCell,
      },
      {
        Header: "Способ доставки",
        accessor: "isShipping",
        Cell: DeliveryCell,
      },
      {
        Header: "Время",
        accessor: "deliveryInfo",
        Cell: TimeCell,
      },
      {
        Header: "Статус",
        accessor: "status",
        Cell: StatusPill,
        Filter: SelectColumnFilter,
      },
      {
        Header: "Сумма заказа",
        accessor: "totalSum",
        Cell: ({ row }) => row.original.delivery + row.original.totalSum,
      },
    ],
    []
  );

  let navigate = useNavigate();
  const setOrder = ({ _id }) => {
    window.open("/orders/" + _id, "_blank");
  };

  const [error, setError] = useState("");
  const [privateData, setPrivateData] = useState("");

  useEffect(() => {
    const fetchPrivateData = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };

      axios
        .get(process.env.REACT_APP_SERVER + "/api/v2/orders", config, {})
        .then((response) => {
          setPrivateData(response.data.reverse().sort(compare));
        })
        .catch((err) => {
          setError(err.response.data.error);
        });
    };

    fetchPrivateData();
  }, []);

  return (
    <>
      <h2 className="ml-10 lg:ml-0 mb-4 text-xl">Список заказов</h2>
      {error ? (
        <span className="error-message">{error}</span>
      ) : privateData ? (
        <Table columns={columns} data={privateData} setItem={setOrder} />
      ) : (
        <span className="animate-spin absolute">
          <RefreshIcon className="w-7 h-7 text-accent "></RefreshIcon>
        </span>
      )}
    </>
  );
};
