import React from "react";
import { NavLink } from "react-router-dom";
import {
  TruckIcon,
  CollectionIcon,
  ShoppingCartIcon,
  CubeIcon,
  PlusIcon,
  ColorSwatchIcon,
  SunIcon,
  MoonIcon,
} from "@heroicons/react/outline";

const navTabs = [
  { name: "Карточки", url: "/cardlist" },
  { name: "Заказы", url: "/orderlist" },
  { name: "Категории", url: "/categories" },
  { name: "Товары", url: "/productlist" },
  { name: "Добавить", url: "/add" },
];

export default function Home() {
  return (
    <div>
      <h2 className="ml-10 lg:ml-0 mb-4 text-xl">Главная страница</h2>
      <nav className="grid gap-2 grid-flow-col justify-center py-10">
        {navTabs.map((navTab) => (
          <NavLink
            to={navTab.url}
            key={navTab.url.slice(1)}
            className="py-8 px-8 rounded-md flex flex-row items-center gap-2 bg-white w-fit shadow-md hover:scale-105 hover:shadow-lg dark:bg-gray-600 dark:hover:text-gray-400"
          >
            <span className="h-10 w-10">
              {
                {
                  Карточки: <ColorSwatchIcon />,
                  Заказы: <TruckIcon />,
                  Категории: <CollectionIcon />,
                  Товары: <ShoppingCartIcon />,
                  Добавить: <PlusIcon />,
                }[navTab.name]
              }
            </span>
          </NavLink>
        ))}
      </nav>
    </div>
  );
}
